<template>
  <div class="logout" >
    <formTitleComponent />
    <div class="container">
      <h1 class="form_div_title text-center">Déconnexion</h1>
      <div class="form_div">
        <div class="form_content">
          <h2 class="text-center"> A bientôt {{ userFirstName }} {{ userLastName }} </h2>
          <b-button pill block variant="outline-dark" class="mt-4 btn-lg" @click="logout">
              Se déconnecter
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const formTitleComponent = () => import('@/components/formTitleComponent')

export default {
  name: 'Logout',
  components: {
    formTitleComponent
  },
  computed: {
    userFirstName () {
      return this.$store.getters.getUserFirstName
    },
    userLastName () {
      return this.$store.getters.getUserLastName
    },
  },
  methods: {
   logout: function () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    },
  }
}
</script>
